.resume {
    height: auto;
    width: 100vw;
    margin: 0 0;
}

.resume iframe{
    height: 100vh;
    width: 100vw;
}
