.helloworld {
    padding: 0 2.5rem;
    background-color: #f9f9fb;
}


@media (max-width: 580px) {
    .helloworld {
        padding: 0;
    }
}
