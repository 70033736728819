.container {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 0;
    margin-bottom: 0;
    background-color: #fff;
}

.container img {
    width: 30vw;
    flex: 1;
    object-fit: cover;
}

.container h1 {
    color: #282c34;
    white-space: pre-wrap;
    text-align: right;
    font-family: var(--font-family);
    font-size: 4vw;
    margin: 4vh 3.5vw 0 0;
    font-weight: bolder;
    flex: 1;
    padding-top: 0;
}

.aboutme {
    flex-direction: column;
    margin: 6vh 3.5vw;
    flex: 2;
    display: block;
}

.aboutme h2 {
    font-family: var(--font-family);
    color: #282c34;
    font-weight: bold;
    font-size: 1.3vw;
    margin-bottom: 4vh;
    text-align: left;
}

.aboutme h3 {
    font-family: var(--font-family);
    color: #282c34;
    font-weight: lighter;
    font-size: 1.1vw;
    text-align: left;
}


@media (max-width: 580px) {
    .aboutme {
        margin: 4vh 2.5vw;
    }

    .container h1 {
        margin: 2vh 3.5vw 0 0;
        font-size: 6vw;
    }


    .aboutme h2 {
        font-size: 2.5vw;

    }

    .aboutme h3 {
        font-size: 2.3vw;
    }
}
