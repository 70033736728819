.contacts {
    display: flex;
    background-color: #282c34;
    flex-direction: row;
    padding: 0 3vw;
    margin-bottom: 0;
    justify-content: space-between;
}

.contacts h1 {
    white-space: pre-wrap;
    text-align: left;
    font-family: var(--font-family);
    font-size: 4vw;
    margin: 6vh 0 0 4vw;
    font-weight: bolder;
    flex: 1;
    padding-top: 0;
    color: #fff;
}

.contactsContainer {
    display: block;
    flex: 2;
    margin-top: 6vh;
}

@media (max-width: 580px) {
    .contacts h1 {
        font-size: 6vw;
    }
}
