.form {
    display: flex;
    justify-content: center;
    align-self: center;
}

.form input {
    font: inherit;
    padding: 0.25rem;
    border: none;
    border-bottom: 3px solid #ccc;
    margin-right: 2rem;
    min-width: 23vw;
    border-radius: 5px;
}

.form input:focus {
    outline: none;
    border-color: #7a0144;
}

.form button {
    background-color: #ac0e77;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: medium;
    padding: 7px;
    color: #fff;
    cursor: pointer;
}
