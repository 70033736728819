.container {
    text-align: center;
    background-color: transparent;
}

/*.container h2 {*/
/*    color: #ac0e77;*/
/*    background-color: transparent;*/
/*}*/

.container h1 {
    color: #ac0e77;
    background-color: transparent;
}

.countDownContainer {
    flex-direction: row;
    display: inline-flex;
    margin: 0 0 3vh 0;
    background-color: #fff;
    padding: 6vh 4vw 1vh 4vw;
    border-radius: 6px;
    position: relative;

}

.countdownSquare {
    background-color: #e3e3e3;
    margin-right: 5px;
    padding: 0 2vw;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    display: flex;
    height: 10vw;
    max-height: 70px;
    width: 3vw;
    flex-wrap: wrap;
    float: left;
}


.colon {
    height: 10vw;
    width: 5px;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-right: 5px;
    display: flex;
    max-height: 70px;
}

.countDownContainer h2 {
    font-size: clamp(.8rem, 1rem, 1.2rem);
    color: #282c34;
    /*line-height: 27px;*/
}

.countDownContainer h3 {
    font-size: clamp(.6rem, .8rem, 1.2rem);
    color: #282c34;
    /*line-height: 27px;*/
}

.countDownContainer h4 {
    font-size: clamp(.8rem, 1rem, 1.8rem);
    color: #ac0e77;
    position: absolute;
    top: -5px;
}

/*SIZE FOR SMALL DEVICES*/
@media (max-width: 580px) {
    .countdownSquare {
        padding: 0 3.4vw;
        height: 11vw;
    }

    .colon {
        height: 11vw;
    }

    /*.countDownContainer h2 {*/
    /*    font-size: 3.1vw;*/
    /*}*/
    /*.countDownContainer h3 {*/
    /*    font-size: 3.1vw;*/
    /*}*/
}
