.introHeader {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0 2rem 0;
}

.introHeader h1 {
    color: #000;
    font-weight: bold;
    font-family: Helvetica, serif;
    font-size: 2.5vh;
    writing-mode: vertical-rl;
    padding: 1rem 0;
    margin: 0 0;
    white-space: pre-wrap;
    word-spacing: 1px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.leftIntro {
    flex-direction: column;
    align-content: space-between;
}

.leftIntro h2 {
    color: #282c34;
    font-weight: lighter;
    font-family: Helvetica, serif;
    font-size: 2.5vh;
    /*background-color: #ac0e77;*/
    padding: 0 0;
    word-spacing: 1px;
    margin: 0.5rem 0;
}

/*SIZE FOR SMALL DEVICES*/
@media (max-width: 580px) {
    .introHeader {
        margin: 0 3vw;
    }
}
